import React, { Component } from 'react'
import bluOne from '../assets/prods/1b.jpg'
import bluTwo from '../assets/prods/2b.jpg'
import bluThree from '../assets/prods/3b.jpg'
import bluFour from '../assets/prods/4b.jpg'
import redOne from '../assets/prods/1r.jpg'
import redTwo from '../assets/prods/2r.jpg'
import redThree from '../assets/prods/3r.jpg'
import redFour from '../assets/prods/4r.jpg'

import WOW from 'wowjs';


export default class Products extends Component {

        // Defining WOW 
        componentDidMount() {
            new WOW.WOW({
              live: false
          }).init();
          }
      
  render() {
    return (
        <>
        <section className='relative md:py-24 py-16 flex justify-center bg-gray-100/75 shadow-lg shadow-gray-200 dark:shadow-jerttblue-700/70 dark:bg-jerttblue-600'>
            <div className='container'>
                <div className='grid grid-cols-1 pb-8 text-center'>
                    <h6 className='text-jerttblue-600 dark:text-zinc-200 text-sm font-bold uppercase mb-2 -mt-9 wow fadeInLeft' data-wow-delay='0.2s'>Disa produkte</h6>
                    <h3 className='mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold dark:text-zinc-100 wow fadeInUp' data-wow-delay='0.2s'>Disa nga produktet tona!</h3>

                    <p className='text-muted-400 max-w-xl mx-auto px-4 dark:text-zinc-200 wow fadeInUp' data-wow-delay='0.2s'>Lorem ipsum dolor sit amet consectetur adipisicing elit!</p>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 md:mt-8 px-8 md:p-2'>
                    <div className='group relative block overflow-hidden rounded-md transition-all duration-500 wow fadeInUp' data-wow-delay='0.1s'>
                        <img src={bluOne} className='group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500' alt=''/>
                        <div className='absolute inset-0 group-hover:bg-jerttblue-700 opacity-70 transition duration-500 z-0'></div>
                        <div className='absolute inset-0 bg-jerttblue-700 opacity-10 z-0'></div>

                        <div className='content'>

                            <div className='title absolute z-10 hidden group-hover:block bottom-4 left-4'>
                                <a href='/' className='text-md font-medium text-white hover:text-jerttblue-600 transition duration-500'>Toyota RAV4</a>
                                <p className='text-slate-100 tag mb-0'>Kushinetë</p>
                            </div>
                        </div>
                    </div>

                    <div className='group relative block overflow-hidden rounded-md transition-all duration-500 wow fadeInUp' data-wow-delay='0.3s'>
                        <img src={redOne} className='group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500' alt=''/>
                        <div className='absolute inset-0 group-hover:bg-jerttblue-700 opacity-70 transition duration-500 z-0'></div>
                        <div className='absolute inset-0 bg-jerttblue-700 opacity-10 z-0'></div>

                        <div className='content'>

                            <div className='absolute z-10 hidden group-hover:block bottom-4 left-4 transition-all duration-500'>
                                <a href='/' className='text-md font-medium text-white hover:text-jerttblue-600 transition duration-500'>Nissan X-Trail</a>
                                <p className='text-slate-100 tag mb-0'>Shpatulla</p>
                            </div>
                        </div>
                    </div>

                    <div className='group relative block overflow-hidden rounded-md transition-all duration-500 wow fadeInUp' data-wow-delay='0.5s'>
                        <img src={bluTwo} className='group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500' alt=''/>
                        <div className='absolute inset-0 group-hover:bg-jerttblue-700 opacity-70 transition duration-500 z-0'></div>
                        <div className='absolute inset-0 bg-jerttblue-700 opacity-10 z-0'></div>

                        <div className='content'>

                            <div className='absolute z-10 hidden group-hover:block bottom-4 left-4 transition-all duration-500'>
                                <a href='/' className='text-md font-medium text-white hover:text-jerttblue-600 transition duration-500'>Nissan Navara D40</a>
                                <p className='text-slate-100 tag mb-0'>Kokë Motorri</p>
                            </div>
                        </div>
                    </div>

                    <div className='group relative block overflow-hidden rounded-md transition-all duration-500 wow fadeInUp' data-wow-delay='0.7s'>
                        <img src={redTwo} className='group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500' alt=''/>
                        <div className='absolute inset-0 group-hover:bg-jerttblue-700 opacity-70 transition duration-500 z-0'></div>
                        <div className='absolute inset-0 bg-jerttblue-700 opacity-10 z-0'></div>

                        <div className='content'>

                            <div className='absolute z-10 hidden group-hover:block bottom-4 left-4 transition-all duration-500'>
                                <a href='/' className='text-md font-medium text-white hover:text-jerttblue-600 transition duration-500'>Nissan Terrano</a>
                                <p className='text-slate-100 tag mb-0'>Spona Qëndrore</p>
                            </div>
                        </div>
                    </div>

                    <div className='group relative block overflow-hidden rounded-md transition-all duration-500 wow fadeInUp' data-wow-delay='0.1s'>
                        <img src={redThree} className='group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500' alt=''/>
                        <div className='absolute inset-0 group-hover:bg-jerttblue-700 opacity-70 transition duration-500 z-0'></div>
                        <div className='absolute inset-0 bg-jerttblue-700 opacity-10 z-0'></div>

                        <div className='content'>

                            <div className='absolute z-10 hidden group-hover:block bottom-4 left-4 transition-all duration-500'>
                                <a href='/' className='text-md font-medium text-white hover:text-jerttblue-600 transition duration-500'>Nissan Terrano</a>
                                <p className='text-slate-100 tag mb-0'>Nyje e Jashtme</p>
                            </div>
                        </div>
                    </div>

                    <div className='group relative block overflow-hidden rounded-md transition-all duration-500 wow fadeInUp' data-wow-delay='0.3s'>
                        <img src={bluThree} className='group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500' alt=''/>
                        <div className='absolute inset-0 group-hover:bg-jerttblue-700 opacity-70 transition duration-500 z-0'></div>
                        <div className='absolute inset-0 bg-jerttblue-700 opacity-10 z-0'></div>

                        <div className='content'>

                            <div className='absolute z-10 hidden group-hover:block bottom-4 left-4 transition-all duration-500'>
                                <a href='/' className='text-md font-medium text-white hover:text-jerttblue-600 transition duration-500'>Mitsubishi L200</a>
                                <p className='text-slate-100 tag mb-0'>Shtopat Mbrapa</p>
                            </div>
                        </div>
                    </div>

                    <div className='group relative block overflow-hidden rounded-md transition-all duration-500 wow fadeInUp' data-wow-delay='0.5s'>
                        <img src={redFour} className='group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500' alt=''/>
                        <div className='absolute inset-0 group-hover:bg-jerttblue-700 opacity-70 transition duration-500 z-0'></div>
                        <div className='absolute inset-0 bg-jerttblue-700 opacity-10 z-0'></div>

                        <div className='content'>

                            <div className='absolute z-10 hidden group-hover:block bottom-4 left-4 transition-all duration-500'>
                                <a href='/' className='text-md font-medium text-white hover:text-jerttblue-600 transition duration-500'>Mitsubishi L200</a>
                                <p className='text-slate-100 tag mb-0'>Federpllatne</p>
                            </div>
                        </div>
                    </div>

                    <div className='group relative block overflow-hidden rounded-md transition-all duration-500 wow fadeInUp' data-wow-delay='0.7s'>
                        <img src={bluFour} className='group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500' alt=''/>
                        <div className='absolute inset-0 group-hover:bg-jerttblue-700 opacity-70 transition duration-500 z-0'></div>
                        <div className='absolute inset-0 bg-jerttblue-700 opacity-10 z-0'></div>

                        <div className='content'>

                            <div className='absolute z-10 hidden group-hover:block bottom-4 left-4 transition-all duration-500'>
                                <a href='/' className='text-md font-medium text-white hover:text-jerttblue-600 transition duration-500'>Toyota</a>
                                <p className='text-slate-100 tag mb-0'>Sensor i pompës së naftës</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
  }
}