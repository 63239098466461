import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Brands from '../components/Brands';
import Analytics from '../components/Analytics';
import Prods from '../components/Prods';
import Info from '../components/Info';
import Footer from '../components/Footer';


const ballina = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <p className='text-zinc-800 font-semibold sm:text-lg p-3 flex justify-center dark:bg-jerttblue-550 dark:text-zinc-100'>Nga brendet:</p>
            <div className='flex justify-center overflow-hidden dark:bg-jerttblue-550 '>
              <Brands />
            </div>
      <Prods />
      <Analytics />
      <Info />
      <Footer />
    </>
  )
}

export default ballina