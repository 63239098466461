import Navbar from '../components/Navbar';
import Products from '../components/Products';
import Footer from '../components/Footer';

const produkte = () => {
  return (
    <>
        <Navbar/>
        <Products/>
        <Footer/>
    </>
  )
}

export default produkte