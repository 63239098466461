// import React, { useRef, useState } from "react";
// Import Swiper React components
import brand1 from '../assets/brands/brand-01.png';
import brand2 from '../assets/brands/brand-02.png';
import brand3 from '../assets/brands/brand-03.png';
import brand4 from '../assets/brands/brand-04.png';
import brand5 from '../assets/brands/brand-05.png';
import brand6 from '../assets/brands/brand-06.png';
import brand7 from '../assets/brands/brand-07.png';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper";

export default function Brands() {
  return (
    <>
      <Swiper
        slidesPerView={6}
        loop={true}
        autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
        breakpoints={{
          0: {
            // width: 576,
            slidesPerView: 3,
          },
          576: {
            // width: 576,
            slidesPerView: 4,
          },
          768: {
            // width: 768,
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 6,
            // spaceBetween: 50,
          },
          1536: {
            slidesPerView: 7,
            // spaceBetween: 50,
          },
        }}
      
        modules={[Autoplay]}
        className="mySwiper w-[80vw] md:w-[76vw] lg:w-[86vw] xl:w-[82vw] "
      >
        <SwiperSlide className="text-gray-500 hover:text-inherit ease-linear duration-500 cursor-pointer px-3 md:px-6"><img src={ brand1 } alt="" /></SwiperSlide>
        <SwiperSlide className="text-gray-500 hover:text-inherit ease-linear duration-500 cursor-pointer px-3 md:px-6"><img src={ brand2 } alt="" /></SwiperSlide>
        <SwiperSlide className="text-gray-500 hover:text-inherit ease-linear duration-500 cursor-pointer px-3 md:px-6"><img src={ brand3 } alt="" /></SwiperSlide>
        <SwiperSlide className="text-gray-500 hover:text-inherit ease-linear duration-500 cursor-pointer px-3 md:px-6"><img src={ brand4 } alt="" /></SwiperSlide>
        <SwiperSlide className="text-gray-500 hover:text-inherit ease-linear duration-500 cursor-pointer px-3 md:px-6"><img src={ brand5 } alt="" /></SwiperSlide>
        <SwiperSlide className="text-gray-500 hover:text-inherit ease-linear duration-500 cursor-pointer px-3 md:px-6"><img src={ brand6 } alt="" /></SwiperSlide>
        <SwiperSlide className="text-gray-500 hover:text-inherit ease-linear duration-500 cursor-pointer px-3 md:px-6"><img src={ brand7 } alt="" /></SwiperSlide>
      </Swiper>
    </>
  );
}
