import Navbar from '../components/Navbar';
import About from '../components/About';
import Footer from '../components/Footer';

const rrethne = () => {
  return (
    <>
        <Navbar/>
        <About/>
        <Footer/>
  </>
  )
}

export default rrethne