import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./pages/ballina"
import Products from "./pages/produkte"
import About from "./pages/rrethne"
import Kontakt from "./pages/kontakt"
import Erri from "./pages/error"
import ScrollToTop from './hook/ScrollToTop';



function App() {

  return (

    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/produkte" element={<Products />} />
        <Route path="/rrethne" element={<About />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/*" element={<Erri />} />
      </Routes>
    </Router>
  );
}

export default App;
