import React, { useState } from 'react';
import useDarkSide from '../hook/useDarkSide';
import { DarkModeSwitch } from 'react-toggle-dark-mode';


export default function Switcher() {
    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState( colorTheme === "light" ? true : false);
// change color while scrolling
    const [color, setColor] = useState(false)
const changeColor = () => {
  if (window.scrollY >= 100) {
    setColor(true)
  } else {
    setColor(false)
  }
}

window.addEventListener('scroll', changeColor)

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme)
        setDarkSide(checked);
    };

  return (
    <>
        <div className="flex">
            <DarkModeSwitch 
                // moonColor='#32a852'
                className='w-[100%]'
                sunColor={color ? 'white' : '#112641'}
                moonColor='white'
                checked={darkSide}
                onChange={toggleDarkMode}
                size={26}
            />
            {/* <div onClick={toggleDarkMode} className="md:hidden px-2 text-zinc-200">Natë Ditë</div> */}
        </div>
    </>
  )
}
