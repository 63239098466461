import Logo from '../assets/logo/logo.svg';
import LogoLight from '../assets/logo/logo-light.svg';
import React, { useState } from "react";
import Switcher from './Switcher';
import SwitcherMob from './SwitcherMob';

import { Link } from 'react-router-dom';
// import { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

const Navbar = () => {
// Mobile nav menu
const [nav, setNav] = useState(false);

const handleNav = () => {
  setNav(!nav);
}
// Mobile nav menu END

// change navbar background color when scrolling
const [color, setColor] = useState(false)
const changeColor = () => {
  if (window.scrollY >= 100) {
    setColor(true)
  } else {
    setColor(false)
  }
}

window.addEventListener('scroll', changeColor)

  return (
      <>
      <div className={color ? 'sticky bg-jerttblue-600 top-0 z-50 ease-in duration-300' : 'sticky bg-white dark:bg-jerttblue top-0 z-50 ease-in duration-500 overflow-hidden'}>
        <nav className='shadow shadow-gray-500'>
          <div className="flex justify-between items-center mx-auto px-8 sm:px-8 md:px-14 lg:px-20 2xl:px-[21rem] shadow-sm w-screen  overflow-hidden py-4 md:py-0">
            <a href="/">
              <img src={color ? LogoLight : Logo} className='w-48 dark:hidden' alt="Logo of Auto Japan | Auto Pjesë" />
              <img src={color ? LogoLight : LogoLight} className='w-48 hidden dark:block' alt="Logo of Auto Japan | Auto Pjesë" /></a>
              <ul className={color ? 'hidden md:flex py-3 font-semibold text-gray-200' : 'hidden md:flex py-3 font-semibold text-black dark:text-gray-200'}>
                  <li className='p-4'><Link to="/">Ballina</Link></li>
                  <li className='p-4'><Link to="/produkte">Produkte</Link></li>
                  <li className='p-4'><Link to="/rrethne">Rreth Ne</Link></li>
                  <li className='p-4'><Link to="/kontakt">Kontakti</Link></li>
                  <li className='p-4'><Switcher /></li>
              </ul>

              {/* ========= Mobile Nav Start ========= */}
            <div onClick={handleNav} className={color ? 'text-zinc-100 block md:hidden' : 'text-jerttblue-700 block md:hidden'}>
              {nav ? <AiOutlineClose size={26} /> : <AiOutlineMenu size={26} />} 
            <div className={nav ? 'fixed w-[40%] right-0 top-0 h-full' : ''}></div>
            </div>            
            <div className={nav ? 'fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-jerttblue-600 text-white py-4 ease-in-out duration-500' : 'fixed left-[-100%] '}>

            <img src={LogoLight} className='w-48 mx-auto' alt="" />

              <ul className='pt-12 p-4'>
                  <Link to="/"><li className='p-4 border-b border-gray-400'>Ballina</li></Link>
                  <Link to="/produkte"><li className='p-4 border-b border-gray-400'>Produkte</li></Link>
                  <Link to="/rrethne"><li className='p-4 border-b border-gray-400'>Rreth Ne</li></Link>
                  <Link to="/kontakt"><li className='p-4 border-b border-gray-400'>Kontakti</li></Link>
                  <li className='p-4 flex'><SwitcherMob /></li>
              </ul>

            </div>
          </div>
        </nav>
      </div>
      </>
)
}

export default Navbar;