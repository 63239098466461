import React, { Component } from 'react'
import WOW from 'wowjs';


export default class Contact extends Component {

        // Defining WOW 
        componentDidMount() {
            new WOW.WOW({
              live: false
          }).init();
          }
      
  render() {
  return (
    < >
        <section className='dark:bg-jerttblue-600 bg-gray-100/75 body-font relative'>
          <div className='container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap'>
            <div className='lg:w-2/3 md:w-1/2 dark:bg-gray-900 bg-gray-800 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative wow fadeInUp' data-wow-delay='0.1s'>
              <iframe title='map' className='absolute inset-0 w-full h-full mapa' frameborder='0' marginheight='0' marginwidth='0' scrolling='no' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46949.79340591112!2d21.099280323433813!3d42.65368176693071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1353637ad8e814d1%3A0x57e6bf8a8ef16f69!2sAUTO%20JAPAN!5e0!3m2!1sen!2s!4v1659962621146!5m2!1sen!2s'></iframe>
              <div className='dark:bg-jerttblue-700 bg-gray-100 relative flex flex-wrap py-6 rounded shadow-md wow fadeInUp' data-wow-delay='0.3s'>
                <div className='lg:w-1/2 px-6'>
                  <h2 className='title-font font-semibold text-jerttblue-700 dark:text-white tracking-widest text-xs'>ADRESA</h2>
                  <p className='mt-1 text-gray-600 dark:text-gray-400'>Rruga Nëna Terezë Fushë Kosovë XK, 12000</p>
                  <a href='https://goo.gl/maps/aorfcbD22ciaBn2h8' target='_blank' rel='noopener noreferrer' className='leading-relaxed text-sm text-jerttblue-200'>Kliko ketu per te marr udhezimet</a>
                </div>
                <div className='lg:w-1/2 px-6 mt-4 lg:mt-0'>
                  <h2 className='title-font font-semibold text-jerttblue-700 dark:text-white tracking-widest text-xs'>EMAIL</h2>
                  <a href='mailto:info@autojapan-ks.com' className='text-jerttblue-200 leading-relaxed'>info@autojapan-ks.com</a>
                  <h2 className='title-font font-semibold text-jerttblue-700 dark:text-white tracking-widest text-xs mt-4'>NUMRI</h2>
                  <a href='tel:+38344539400' className='leading-relaxed text-jerttblue-200 pr-4'>+383 44 539 400 </a>
                  {'  '}
                  <a href='tel:+38349251000' className='leading-relaxed text-jerttblue-200'> +383 49 251 000</a>
                </div>
              </div>
            </div>
            <div className='lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0'>
              <h2 className='dark:text-white text-jerttblue-700 text-lg mb-1 font-medium title-font wow fadeInUp' data-wow-delay='0.2s'>Forma e Kontaktit</h2>
              <p className='leading-relaxed mb-5 text-gray-600 dark:text-gray-400 wow fadeInUp' data-wow-delay='0.3s'>Per cdo lloj pyetjeje ndjehuni te lirë te na kontaktoni!</p>
              <div className='relative mb-4 wow fadeInUp' data-wow-delay='0.1s'>
                <label for='name' className='leading-7 text-sm text-jerttblue dark:text-gray-400'>Emri</label>
                <input type='text' id='name' name='name' className='w-full dark:bg-gray-800 bg-gray-100 rounded border dark:border-gray-700 border-gray-500 focus:border-jerttblue-300 focus:ring-2 dark:focus:ring-jerttblue-700 text-base outline-none text-jerttblue-800 dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'/>
              </div>
              <div className='relative mb-4 wow fadeInUp' data-wow-delay='0.1s'>
                <label for='email' className='leading-7 text-sm text-jerttblue dark:text-gray-400'>Email</label>
                <input type='email' id='email' name='email' className='w-full dark:bg-gray-800 bg-gray-100 invalid:border-jerttred-500 invalid:text-jerttred-600 focus:invalid:border-jerttred-500/60 focus:invalid:ring-jerttred-500/50 rounded border dark:border-gray-700 border-gray-500 focus:border-jerttblue-300 focus:ring-2 dark:focus:ring-jerttblue-700 text-base outline-none text-jerttblue-800 dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'/>
              </div>
              <div className='relative mb-4 wow fadeInUp' data-wow-delay='0.1s'>
                <label for='message' className='leading-7 text-sm text-jerttblue dark:text-gray-400'>Mesazhi Juaj</label>
                <textarea id='message' name='message' className='w-full dark:bg-gray-800 bg-gray-100 rounded border dark:border-gray-700 border-gray-500 focus:border-jerttblue-300 focus:ring-2 dark:focus:ring-jerttblue-700 h-32 text-base outline-none text-jerttblue-800 dark:text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out'></textarea>
              </div>
              <button className='text-white bg-jerttblue-300 border-0 py-2 px-6 focus:outline-none hover:bg-jerttblue-400 ease-linear duration-300 rounded text-lg wow fadeInRight' data-wow-delay='0.1s'>Dërgoni</button>
              <p className='text-xs text-jerttblue dark:text-gray-400 text-opacity-90 mt-3'>Nje tekst ketu.</p>
            </div>
          </div>
        </section>
    </>
  );
};
};