// import Logo from '../assets/logo/logo.svg';
import LogoLight from '../assets/logo/logo-light.svg';
import {
  FaFacebookSquare,
  FaInstagram,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <>
    <footer className="w-full bg-jerttblue-700 border-t-[1px] border-t-gray-700 shadow-inner shadow-gray-800">

    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8'>
      <div>
        <img src={LogoLight} className='w-44 text-3xl font-bold'  alt='Logo of Auto Japan | Auto Pjesë'/>
        <p className='py-4 text-gray-300/80'>Të gjitha pjesët ORIGJINALE të veturave japoneze, koreane dhe amerikane mund t'i gjeni vetëm tek AUTO JAPAN në Fushë Kosovë dhe Pejë</p>
        <div className='flex justify-start space-x-4 md:w-[75%] my-6'>
            <a href="https://www.facebook.com/AUTOJAPAN.KS" target="_blank" rel="noopener noreferrer"><FaFacebookSquare className='text-gray-400 hover:text-gray-300 ease-linear duration-300 cursor-pointer' size={30} /></a>
            <a href="https://www.instagram.com/autojapan.fk/" target="_blank" rel="noopener noreferrer"><FaInstagram className='text-gray-400 hover:text-gray-300 ease-linear duration-300 cursor-pointer' size={30} /></a>
        </div>
      </div>
      <div className='lg:col-span-2 flex justify-between md:justify-end md:space-x-32 mt-6'>
    <div>
        <h6 className='font-medium text-white/90'>Brendet</h6>
        <ul className='text-gray-300/50'>
            <li className='py-2 text-sm'>Brendi1</li>
            <li className='py-2 text-sm'>Brendi2</li>
            <li className='py-2 text-sm'>Brendi3</li>
            <li className='py-2 text-sm'>Brendi4</li>
            {/* <li className='py-2 text-sm'>Brendi5</li> */}
        </ul>
    </div>
    {/* <div>
        <h6 className='font-medium text-jerttblue-400'>Ndihma</h6>
        <ul>
            <li className='py-2 text-sm'>Pricing</li>
            <li className='py-2 text-sm'>Documentation</li>
            <li className='py-2 text-sm'>Guides</li>
            <li className='py-2 text-sm'>API Status</li>
        </ul>
    </div> */}
    <div>
        <h6 className='font-medium text-white/90'>Auto Japan</h6>
        <ul className='text-gray-300/50'>
            <li className='py-2 text-sm'><a href="/">Ballina</a></li>
            <li className='py-2 text-sm'><a href="/">Produkte</a></li>
            <li className='py-2 text-sm'><a href="/">Rreth Ne</a></li>
            <li className='py-2 text-sm'><a href="/">Kontakti</a></li>
            {/* <li className='py-2 text-sm'><a href="/">FAQ</a></li> */}
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-white/90'>Legal</h6>
        <ul className='text-gray-300/50'>
            <li className='py-2 text-sm'>Ndihma</li>
            <li className='py-2 text-sm'>Politikat</li>
            <li className='py-2 text-sm'>Termet </li>
        </ul>
    </div>
      </div>
    </div>
    <div class="bg-jerttblue-800/25  dark:bg-jerttblue-900/25 bg-opacity-75  mx-auto md:px-4 ">
    <div class="container mx-auto py-4 px-5 flex justify-center flex-wrap flex-col sm:flex-row">
      <p class="text-gray-400 text-sm text-center sm:text-left">© {new Date().getFullYear()} Auto Japan —
        <a href="https://3ambrand.com/" class="text-gray-500 ml-1" rel="noopener noreferrer" target="_blank">3AM Brand Communication</a>
      </p>
    </div>
  </div>
    </footer>
    </>
  );
};

export default Footer;