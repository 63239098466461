// import Office2 from '../assets/off2.png';
import Office from '../assets/office.png';
import { FaMapMarkerAlt } from 'react-icons/fa';

import React, { Component } from 'react'
import WOW from 'wowjs';


export default class Analytics extends Component {

        // Defining WOW 
        componentDidMount() {
            new WOW.WOW({
              live: false
          }).init();
          }
      
  render() {
  return (
    <div className='w-full pt-4 md:py-24 mx-auto px-6 shadow-lg shadow-gray-200 dark:shadow-gray-600 dark:bg-jerttblue-550'>
      <div className='max-w-[1240px] mx-auto grid lg:grid-cols-2 justify-center'>
        <div className='relative align-middle mr-10'>
        <img className='md:w-[500px] top-0 left-0 mx-4 my-4 rounded-2xl wow fadeInLeft' data-wow-delay='0.1s' src={Office} alt='Foto nga lokali i Auto Japan ne Fushë Kosovë' />
        <img className='w-[45vw] md:w-[300px] absolute -bottom-10 -right-10 rounded-2xl shadow-2xl shadow-gray-400 dark:shadow-jerttblue-600 wow fadeInLeft' data-wow-delay='0.2s' src={Office} alt='Foto nga lokali i Auto Japan ne Pejë' />
        </div>
        <div className='flex flex-col justify-center md:pl-12 py-16 px-2 wow fadeInUp' data-wow-delay='0.3s'>
          <p className='text-jerttblue dark:text-zinc-200 font-bold'>Lokacionet</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-jerttblue dark:text-zinc-200'>Fushë Kosovë &amp; Pejë</h1>
          <p className='font-medium text-jerttblue flex my-1 dark:text-zinc-300 text-left text-sm md:text-base'><span className='mt-1 pr-2 dark:text-zinc-50 text-jerttred'><FaMapMarkerAlt /></span>Fushë Kosovë përballë Euromed pranë mbikalimit</p>
          <p className='font-medium text-jerttblue truncate flex my-1 dark:text-zinc-300 text-left text-sm md:text-base'><span className='mt-1 pr-2 dark:text-zinc-50 text-jerttred'><FaMapMarkerAlt className=''/></span>Rr.UÇK, Komp. Peja Premium Residence, Pejë</p>
          <p className='font-medium text-jerttblue flex my-1 dark:text-zinc-300 text-sm md:text-base text-left'>Auto Japan tashmë hap edhe pikën e re në Pejë, ku mundë të gjeni të gjitha pjesët orgjinale të veturave Japane, Koreane dhe Amerikane nga brende të ndryshme me kualitet të garantuar!</p>
          <a href='/' className='bg-jerttred hover:bg-jerttred-600 dark:bg-zinc-100 dark:hover:bg-jerttblue-200 dark:text-jerttblue-800 dark:hover:text-white ease-linear duration-300 text-white w-[200px] rounded-md font-medium my-6  md:mx-0 py-3 text-center'>Shiko ne Google Maps</a>
        </div>
      </div>
    </div>

    // THE ONE IMAGE COMPONENT
    // <div className='w-full bg-white py-24 mx-auto px-4'>
    //   <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
    //     <div className='relative'>
    //     <img className='md:w-[550px] top-0 left-0 mx-4 my-4 rounded-2xl' src={Office2} alt='/' />
    //     {/* <img className='w-[250px] md: absolute top-0 left-0 mt-60 ml-72 rounded-2xl shadow-2xl shadow-gray-400' src={Office} alt='/' /> */}
    //     </div>
    //     <div className='flex flex-col justify-center md:pl-8 px-4'>
    //       <p className='text-ajp font-bold '>Lokacionet e Auto Japan </p>
    //       <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Fushë Kosovë &amp; Pejë</h1>
    //       <p className='flex my-1'><span className='mt-1 pr-2'><GrLocation /></span>Fushë Kosovë përballë Euro Delta</p>
    //       <p className='flex my-1'><span className='mt-1 pr-2'><GrLocation /></span>Rr.UÇK, Kompleksi Peja Premium Residence, Pejë</p>
    //       <button className='bg-black text-white w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Shiko ne Google Maps</button>
    //     </div>
    //   </div>
    // </div>
  );
};
};
// export default Analytics