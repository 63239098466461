import React, { Component } from 'react'
import { FiHelpCircle } from 'react-icons/fi'
import { Link } from 'react-router-dom';

import WOW from 'wowjs';

export default class Products extends Component {

        // Defining WOW 
        componentDidMount() {
            new WOW.WOW({
              live: false
          }).init();
          }
      
  render() {
    return (
        <>
        <section className='relative md:py-24 py-16 px-8 bg-gray-100/75 dark:shadow-jerttblue-700/70 dark:bg-jerttblue-600 shadow-md shadow-gray-600'>
            <div className='flex justify-center'>
        <div className='container'>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-[30px] items-center'>
                <div className='flex md:mt-4 wow fadeInUp' data-wow-delay='0.1s'>
                    <FiHelpCircle className='w-6 h-6 text-jerttblue dark:text-zinc-50 mr-3'/>
                    <div className='flex-1'>
                        <h5 className='mb-2 text-xl font-extrabold text-jerttred dark:text-zinc-100'>Kush është Auto Japan?</h5>
                        <p className='text-muted-400 dark:text-zinc-300'>Nëse jeni duke kërkuar për pjesë të veturave Japoneze, Koreane dhe Amerikane me cilësi të lartë, ky është vendi i duhur për ju. Ne ju ofrojmë produktet më të mira të mundshme me shërbimin më të mirë të mundshëm të klientit.</p>
                    </div>
                </div>
                
                <div className='flex mt-4 wow fadeInUp' data-wow-delay='0.2s'>
                    <FiHelpCircle className='w-6 h-6 text-jerttblue dark:text-zinc-50 mr-3'/>
                    <div className='flex-1'>
                        <h5 className='mb-2 text-xl font-extrabold text-jerttred dark:text-zinc-100'> Si ta bëjmë Porosinë Online !</h5>
                        <p className='text-muted-400 dark:text-zinc-300'>Zgjedh produktin të cilin dëshironi ta porositni online. Për të bërë porosi duhen të dhënat si: Emri dhe Mbiemri, Nr.Kontaktues dhe Adresa e Qytetit.
                            Porosinë mund ta bëni në: Inbox Facebook, DM Instagram ose në numrat kontaktues!
                        </p>
                    </div>
                </div>
    
                <div className='flex wow fadeInUp' data-wow-delay='0.1s'>
                    <FiHelpCircle className='w-6 h-6 text-jerttblue dark:text-zinc-50 mr-3'/>
                    <div className='flex-1'>
                        <h5 className='mb-2 text-xl font-extrabold text-jerttred dark:text-zinc-100'> Qfarë pjesë ofron Auto Japan?</h5>
                        <p className='text-muted-400 dark:text-zinc-300'>Të gjitha pjesët orgjinale për vetura Japane, Koreane dhe Amerikane i gjeni tek Auto Japan.</p>
                    </div>
                </div>
                
                <div className='flex wow fadeInUp' data-wow-delay='0.2s'>
                    <FiHelpCircle className='w-6 h-6 text-jerttblue dark:text-zinc-50 mr-3'/>
                    <div className='flex-1'>
                        <h5 className='mb-2 text-xl font-extrabold text-jerttred dark:text-zinc-100'> Në cilat vende ofroni transport?</h5>
                        <p className='text-muted-400 dark:text-zinc-300'>Auto Japan bën transport në Kosovë, Shqipëri dhe Maqedoni.</p>
                    </div>
                </div>
                
            </div>
            {/* end grid */}
        </div>
        {/* end container */}
            </div>
    
        <div className='flex justify-center wow fadeInUp' data-wow-delay='0.2s'>
            <div className='container md:mt-24 mt-16 md:mb-12'>
                <div className='grid grid-cols-1 text-center'>
                    <h3 className='mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold text-jerttblue-800 dark:text-zinc-100'>Keni diçka të paqartë? Kontakto!</h3>
    
                    <p className='text-muted-400 max-w-xl mx-auto px-2 text-jerttblue-800 dark:text-zinc-300'>Nëse jeni duke kërkuar për pjesë të veturave Japoneze, Koreane dhe Amerikane me cilësi të lartë, ky është vendi i duhur për ju.</p>
    
                    <div className='mt-6'>
                        <Link to="/kontakt"><a href className='bg-jerttred hover:bg-jerttblue text-white dark:bg-zinc-100 dark:hover:bg-jerttblue-200 dark:text-jerttblue-800 dark:hover:text-white btn-jj'> Kontakto</a></Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    )
  }
}
