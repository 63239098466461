import React, { Component } from 'react'
import WOW from 'wowjs';

import uno1 from '../assets/about/uno1.webp'
import uno2 from '../assets/about/uno2.webp'
import uno3 from '../assets/about/uno3.webp'

import duo1 from '../assets/about/duo1.webp'
import duo2 from '../assets/about/duo2.webp'
import duo3 from '../assets/about/duo3.webp'


export default class About extends Component {

        // Defining WOW 
        componentDidMount() {
            new WOW.WOW({
              live: false
          }).init();
          }
  render() {
  return (
    <section className='w-full bg-gray-100/75 shadow-lg shadow-gray-600 dark:shadow-jerttblue-700/70 dark:bg-jerttblue-600'>
      <div className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
        <div className='grid gap-10 lg:grid-cols-2'>
          <div className='flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg'>
            <div className='max-w-xl mb-6'>
              <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-jerttblue-600 dark:text-zinc-100 sm:text-4xl sm:leading-none wow fadeInLeft' data-wow-delay='0.1s'>
                <span className='inline-block text-jerttred mb-1'>
                Për pjese të veturave{' '}
                </span>
                <br className='hidden md:block'/>
                 {' '}e dini ku  me ardhë
              </h2>
               
              <p className='text-base text-gray-700 dark:text-zinc-200 md:text-lg wow fadeInLeft' data-wow-delay='0.3s'>
                <span className='text-gray-400'>Nje tekst ketu per historikun e auto japan! </span>
              Ne ju ofrojmë produktet më të mira të mundshme me shërbimin më të mirë të mundshëm të klientit.
              </p>
            </div>
            <div>
              <a href='/' aria-label='' className='inline-flex items-center font-semibold transition-colors duration-300 text-jerttred hover:text-jerttred-700 wow fadeInLeft' data-wow-delay='0.3s'>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='mr-2' viewBox='0 0 16 16'>
                  <path fill-rule='evenodd' d='M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z'/>
              </svg>
                Kliko ketu për të shikuar lokacionet</a>
            </div>
          </div>
          <div className='flex items-center justify-center -mx-4 lg:pl-8'>
            <div className='flex flex-col items-end px-3'>
              <img
                className='object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56 bg-gray-500 wow fadeInRight' data-wow-delay='0.3s'
                src={uno2}
                alt='Auto Japan Ne Peje'
              />
              <img
                className='object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40 bg-gray-500 wow fadeInRight' data-wow-delay='0.05s'
                src={uno3}
                alt='Auto Japan Per qdo pjese te makines neve na ki'
              />
            </div>
            <div className='px-3'>
              <img
                className='object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80 bg-gray-500 wow fadeInRight' data-wow-delay='0.5s'
                src={uno1}
                alt='Auto Japan Ate qe ju duhet ne e ekemi'
              />
            </div>
          </div>
        </div>
        <div className='grid gap-10 lg:grid-cols-2 py-8'>

        <div className='flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg lg:hidden'>
            <div className='max-w-xl mb-6'>
              <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-jerttblue-600 sm:text-4xl sm:leading-none wow fadeInRight dark:text-zinc-200' data-wow-delay='0.1s'>
                  Çdo gjë që{' '}
                <br className='hidden md:block' />
                    ju{' '}
                <span className='inline-block text-jerttred mt-1 '>
                  duhet!
                </span>
              </h2>
              <p className='text-base text-gray-700 dark:text-zinc-200 md:text-lg wow fadeInRight' data-wow-delay='0.3s'>
              Të specializuar për të gjitha pjesët e veturave Japane tani ju shumë lehtë mund ti gjeni tek Auto Japan përballë Euro Delta në Fushë Kosovë.
              Për ndonjë informacion të mëtutjeshëm para se të porosisni pjesët e automjetit tuaj tek ne, atëherë ekipi ynë i përkushtuar është në dispozicion me këshilla dhe ndihmë gjatë çdo gjëje.
              </p>
            </div>
            <div>
              <a href='/' aria-label='' className='inline-flex items-center font-semibold transition-colors duration-300 text-jerttred hover:text-jerttred-700 wow fadeInRight' data-wow-delay='0.3s'>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='mr-2' viewBox='0 0 16 16'>
                  <path fill-rule='evenodd' d='M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z'/>
              </svg>
                Na vizitoni tani!</a>
            </div>
          </div>

        <div className='flex items-center justify-center -mx-4 lg:pl-8'>
            <div className='flex flex-col items-end px-3'>
              <img
                className='object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56 bg-gray-500 wow fadeInLeft' data-wow-delay='0.3s'
                src={duo2}
                alt='Auto Japan pjese t endryshme te veturave, Jeep, Honda, Mazda, Mitsubishi, Toyota dhe Nissan'
              />
              <img
                className='object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40 bg-gray-500 wow fadeInLeft' data-wow-delay='0.1s'
                src={duo3}
                alt='Te gjitha pjeset ne nje vend per vetura offroad'
              />
            </div>
            <div className='px-3'>
              <img
                className='object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80 bg-gray-500 wow fadeInLeft' data-wow-delay='0.5s'
                src={duo1}
                alt='Auto Japan Të gjitha ne nje vend'
              />
            </div>
          </div>

          <div className='hidden lg:flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg'>
            <div className='max-w-xl mb-6'>
              <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-jerttblue-600 sm:text-4xl sm:leading-none wow fadeInRight  dark:text-zinc-200' data-wow-delay='0.1s'>
                  Çdo gjë që{' '}
                <br className='hidden md:block' />
                    ju{' '}
                <span className='inline-block text-jerttred mt-1 '>
                  duhet!
                </span>
              </h2>
              <p className='text-base text-gray-700 dark:text-zinc-200 md:text-lg wow fadeInRight' data-wow-delay='0.3s'>
                Të specializuar për të gjitha pjesët e veturave Japane tani ju shumë lehtë mund ti gjeni tek Auto Japan përballë Euro Delta në Fushë Kosovë.
                  Për ndonjë informacion të mëtutjeshëm para se të porosisni pjesët e automjetit tuaj tek ne, atëherë ekipi ynë i përkushtuar është në dispozicion me këshilla dhe ndihmë gjatë çdo gjëje.
              </p>
            </div>
            <div>
              <a href='/' aria-label='' className='inline-flex items-center font-semibold transition-colors duration-300 text-jerttred hover:text-jerttred-700 wow fadeInRight' data-wow-delay='0.3s'>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='mr-2' viewBox='0 0 16 16'>
                  <path fill-rule='evenodd' d='M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z'/>
              </svg>
                Na vizitoni tani!</a>
            </div>
          </div>

        </div>
      </div>
  </section>
  );
};
};
// export default Analytics