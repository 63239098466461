import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const error = () => {
  return (
    <>
      <Navbar/>
      <Footer/>
    </>
  )
}

export default error