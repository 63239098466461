import React, { Component } from 'react'
import Typed from 'react-typed';
// import MyBackgroundImage from '../assets/test-01.png';
import MyBackgroundImage from '../assets/prods/test2.webp';

import WOW from 'wowjs';


export default class Hero extends Component {

        // Defining WOW 
        componentDidMount() {
            new WOW.WOW({
              live: false
          }).init();
          }
      
  render() {
  return (
    <div className='text-jerttblue-400 overflow-hidden bg-no-repeat bg-center bg-cover relative -z-10 md:h-[700px] h-[400px]'
    style={{
      backgroundImage: `url(${MyBackgroundImage})`,
      // backgroundImage: `url(${externalImage})`,
      // height: '700px',
      // backgroundPositionY: '5%',
    }}
    >
    <div className='absolute inset-0 bg-jerttblue-700/60 overflow-hidden -z-10'></div>
      {/* <div className='absolute inset-0 bg-black/70 z-0 h-[600px]'></div> */}
        <div className='max-w-[800px] -mt-32 w-full h-[92vh] mx-auto z-50 text-center flex flex-col justify-center'>
            {/* <p className='text-zinc-100 font-semibold'>Lokacioni: Fushë Kosovë dhe Pejë</p> */}
            {/* <h1 className='text-gray-100 text-6xl md:text-7xl sm:text-6xl font-black md:py-4'><span className='text-gray-200'>AUTO</span> JAPAN</h1> */}
            <div>
                <h2 className='md:text-5xl sm:text-4xl text-2xl font-medium text-white px-4 wow fadeInUp' data-wow-delay='0.1s'>Pjesë origjinale të veturave
                <p className='pt-4'></p>
                <Typed className='font-semibold' strings={[' Japoneze,', ' Koreane', ' dhe Amerikane']} typeSpeed={120} backSpeed={140} loop />
                </h2>
                
            </div>
        </div>
    </div>
  );
};
};
// export default Analytics