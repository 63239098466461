import React, { useState } from 'react';
import useDarkSide from '../hook/useDarkSide';
import { DarkModeSwitch } from 'react-toggle-dark-mode';


export default function SwitcherMob() {
    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState( colorTheme === "light" ? true : false);

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme)
        setDarkSide(checked);
    };

  return (
    <>
        <div className="flex">
            <DarkModeSwitch
                className='w-[100%]'
                sunColor={'white'}
                moonColor='white'
                checked={darkSide}
                onChange={toggleDarkMode}
                size={26}
            />
            {/* <div onClick={toggleDarkMode} className="md:hidden px-2 text-zinc-200">Natë Ditë</div> */}
        </div>
    </>
  )
}
