import Navbar from '../components/Navbar';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const kontakti = () => {
  return (
    <>
      <Navbar />
      <Contact />
      <Footer />
    </>
  )
}

export default kontakti